.GetInvolvedForm label {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-family: 'new-science-serif', 'sans-serif';
}

.GetInvolvedForm input {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: sans-serif;
}

.GetInvolvedForm select {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: .05rem;
  font-family: sans-serif;
}

.GetInvolvedForm input::placeholder {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: sans-serif;
}

.GetInvolvedForm-button:hover {
  background-color: #710071;
}