.GetInvolvedPage {
  margin-bottom: -7rem;
}

.GetInvolvedPage-hero-desktop {
  display: none;
  }

.GetInvolvedPage-hero-mobile {
  width: 100%;
  height: auto;
  margin-bottom: 3rem;
}

.GetInvolvedPage-footer-mobile {
  width: 100%;
  height: auto;
  margin-top: 3rem;
}

.GetInvolvedPage h1 {
  font-size: 2.25rem;
}

.GetInvolvedPage-content {
  margin-left: 1rem;
  margin-right: 1rem;
}

.GetInvolvedPage li {
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-left: 1rem;
}

@media screen and (min-width: 600px) {
  .GetInvolvedPage {
    margin-bottom: 1rem;
  }

  .GetInvolvedPage-hero-mobile {
    display: none;
  }

  .GetInvolvedPage-footer-mobile {
    display: none;
  }

  .GetInvolvedPage-hero-desktop {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 3rem;
  }

  .GetInvolvedPage h1 {
    font-size: 2.85rem;
  }

  .GetInvolvedPage-content {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .GetInvolvedPage li {
    margin-left: 4rem;
  }
}