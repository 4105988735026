.BioList {
  position: relative;
  z-index: 10;
}

.BioList-header {
  width: 100%;
  text-align: center;
  margin-bottom: 1.875rem;
}

.BioList-header p {
  margin-left: auto;
  margin-right: auto;
}

.BioList-bios {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 4rem;
  column-gap: 2.5rem;
  row-gap: 3.5rem;
}

.BioList-bingo {
  display: none;
}

@media screen and (min-width: 1000px) {
  .BioList-bios {
    margin-left: 5.313rem;
    margin-right: 5.313rem;
  }

  .BioList-bingo {
    display: block;
    position: absolute;
    top: 40%;
    left: 40%;
    z-index: 0;
  }

  .BioList-header p {
    width: 50%;
  }
}