.subscribe {
  background-color: #ffff;
  border: none;
  color: #000;
  width: 100%;
  height: 3.875rem;
  border-radius: 30px;
  font-family: 'Hiragino Kaku Gothic', sans-serif;
  font-weight: 800;
  letter-spacing: .15em;
  font-size: 20px;
  margin: 1em 0;
  transition-duration: 300ms;
  transition-timing-function: ease-in;
}

.subscribe:hover {
  background-color: #E21674;
  color: white;
}

.footer-row input {
  width: 100%;
  height: 4.375rem;
  border-radius: 8px;
  border: none;
  background-color: #5576aa;
  margin-left: auto;
  margin-right: auto;
  padding-left: .5rem;
}

input::placeholder {
  color: #ffffff;
  font-family: 'new-science-serif', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: .1em;
  text-transform: uppercase;
}

/* Target text input by the user */
input[type="text"] {
  color: #ffffff;
  font-family: 'new-science-serif', sans-serif;
}