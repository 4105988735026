.FAQPage {
  margin-top: 4rem;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 1rem;
}

.FAQPage p {
  font-size: 1.15rem;
}

/* Margins and paragraph font size for desktop styling */
@media screen and (min-width: 800px) {
  .FAQPage {
    margin-left: 162px;
    margin-right: 180px;
  }

  .FAQPage p {
    font-size: 1.5rem;
  }
}

.FAQPage-questions {
  margin-top: 6.25rem;
}

.accordion-item {
  border: 0px;
  margin-bottom: 2rem;
}

.accordion-button {
  font-family: "new-science-serif", sans-serif;
  font-size: 1.875rem;
  font-weight: 500;
  color: black;
  background-color: white;
  box-shadow: none;
  padding-left: 0px;
  padding-right: 0px;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: white;
  box-shadow: none;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-body {
  font-family: "new-science-serif", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  margin-top: 2rem;
  padding-left: 0px;
  padding-right: 0px;
}

.FAQPage-bar {
  margin-top: 0rem;
  height: 2px;
  width: 100%;
  border-radius: 3px;
  background: linear-gradient(90deg, red 0%, yellow 15%, lime 30%, cyan 50%, blue 65%, magenta 80%, red 100%);
  background-size: 200% 200%;
  animation: horizontalScroll 5s linear infinite;
  filter: blur(.7px);
}

@keyframes horizontalScroll {
  0% {
      background-position: 0% 0%
  }

  100% {
      background-position: -200% 0%;
  }
}