.mobile-menu {
    width: 100vw;
    height: 100vh;
    background-color: rgba(35, 34, 37, 0.166);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100 !important;
    opacity: 0;
    box-shadow: 0px 29px 80px rgba(0, 0, 0, 0.741);
    transition: all 0.3s ease;
    transform: translateX(-100vw);
    z-index: 0;
}

.mobile-menu-container {
    width: 45vw;
    min-width: 45vw;
    height: 100vh;
    background-color: #ffffff;
    padding: 2rem;
}

.mobile-menu.active {
    opacity: 1;
    transform: translateX(0);
}

.logo-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: 2rem 0rem;
}

#root>div>div.mobile-menu.active>div>button,
#root>div>div.mobile-menu>div>button {
    top: 2.8rem;
    left: 0rem;
}

.menu-btn {
    position: absolute;
    border-radius: 50%;
    height: 2.6em;
    width: 2.6em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2em;
    animation: fadeIn 2s ease 0s 1 normal forwards;
}

.material-symbols-outlined {
    font-size: 2.8rem;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.6);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.menu-btn:active {
    animation: fadeOut 2s ease 0s 1 normal forwards;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(0.6);
    }
}

.mobile-nav-play-btn {
    border-radius: 30px;
    border: 0px solid transparent;
    width: 96% !important;
    height: 70px;
    z-index: 999s;
}

.mobile-nav-play-btn a {
    font-weight: 800;
    font-size: 1.5em;
    line-height: 36px;
    letter-spacing: .15em;
    font-variant: normal;
    color: #fff;
    text-decoration: none;
}

.outline {
    height: 3.5rem;
    width: 3.5rem;
    outline: .2rem solid #fff;
    border-radius: 15px;
    padding: 8px;
    margin: 0;
    position: absolute;
    z-index: 1;
    transition: all .3s ease;
}

#root>div>div.mobile-menu>div>ul {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    list-style: none;
    margin-left: -1rem;
    margin-top: 11em;
}

.mobile-menu-item {
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    margin: 0 1rem;
    color: #8d8d8d;
    position: relative;
    cursor: pointer;

}

.mobile-menu-item::before {
    content: " ";
    width: 2rem;
    height: 0.3rem;
    background: #e11774;
    border-radius: 0.5rem;
    position: absolute;
    opacity: 0;
    bottom: -0.6rem;
    transform: translateX(-1rem);
}

.mobile-menu-item:hover::before {
    width: 100%;
    transform: translateX(0rem);
    opacity: 1;
}

#root>div>div.mobile-menu>div>button.btn-info.btn-info {
    position: absolute;
    margin: 75vh auto;
    width: 100%;
    left: 2%;
}

#root>div>div.mobile-menu.active>div>button.btn-info.nav-play-btn {
    display: block;
    min-width: 96%;
}

@media(max-width: 869px) and (min-width: 320px) {
    .mobile-menu-container {
        min-width: 55vw;
        height: 100vh;
        background-color: #ffffff;
        padding: 2rem;
    }

    #root>div>div.mobile-menu>div>ul {
        display: flex;
        flex-direction: column;
        gap: 4rem;
        list-style: none;
        margin-left: -1rem;
        margin-top: 11em;
    }


    .material-symbols-outlined {
        font-size: 2.8rem;
    }

    #root>div>div.mobile-menu>div>button.btn-info.btn-info {
        display: block;
    }

    #root>div>div.mobile-menu.active>div>button,
    #root>div>div.mobile-menu>div>button {
        visibility: visible;
    }

    #root>div>nav {
        width: 100%;
        display: flex;
        justify-self: center;
        align-items: center;
    }
}

@media (max-width: 775px) {
    #root>div>nav>div>div.d-flex.align-items-center.justify-content-between.position-relative {
        min-width: 100%;
    }

    .menu-btn {
        visibility: visible;
    }

    .nav-wrapper {
        min-width: 100%;
    }
}

@media (max-width: 575px) and (min-width: 269px) {
    .menu-btn {
        position: absolute;
        left: 0;
    }
}

@media (max-width: 546px) and (min-width: 369px) {

    .mobile-menu-container {
        min-width: 100vw;
        height: 100vh;
        background-color: #ffffff;
        padding: 2rem;
    }

    .menu-btn {
        border-radius: 50%;
        height: 2.6em;
        width: 2.6em;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        visibility: visible;
        left: 1em;

    }

    .material-symbols-outlined {
        font-size: 2.8rem;
    }
}

@media (max-width: 369px) and (min-width: 99px) {

    .mobile-menu-container {
        min-width: 100vw;
        height: 100vh;
        background-color: #ffffff;
        padding: 2rem;
    }

    #root>div>div.mobile-menu>div>ul {
        display: flex;
        flex-direction: column;
        gap: 5rem;
        list-style: none;
        margin-left: -1rem;
        margin-top: 8em;
    }

    .menu-btn {
        border-radius: 50%;
        height: 2.6em;
        width: 2.6em;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        visibility: visible;
    }

    .material-symbols-outlined {
        font-size: 2.8rem;
    }


    #root>div>div.mobile-menu>div>ul {
        display: flex;
        flex-direction: column;
        gap: 4rem;
        list-style: none;
        margin-left: -1rem;
        margin-top: 11em;
    }


    #root>div>div.nav-wrapper>div>div>div>a>img {
        height: 4.5em;
        width: auto;
    }

    #root>div>div.nav-wrapper>div>button.menu-btn {
        height: 2.9rem;
        width: 2.9rem;
        left: .5em;
    }

    #root>div>div.nav-wrapper>div>ul {
        display: none;
    }
}