.BioCard-purple, .BioCard-orange, .BioCard-blank {
  position: relative;
  z-index: 10;
  text-align: center;
  padding-bottom: .5rem;
  width: 40%;
  transition-duration: 300ms;
  transition-timing-function: ease-in;
}

.BioCard-graphics {
  position: relative;
  z-index: 40;
}

.BioCard-purple:hover {
  background-color: #7030A0;
  border-radius: 55px;
  color: white;
}

.BioCard-orange:hover {
  background-color: #FFA629;
  border-radius: 55px;
  color: black;
}

.BioCard-purple:hover .BioCard-drawing {
  opacity: 1;
  cursor: pointer;
}

.BioCard-purple:hover .BioCard-blank-hover {
  opacity: 1;
  cursor: pointer;
}

.BioCard-purple:hover .BioCard-blank-img {
  opacity: 0;
}

.BioCard-purple:hover .BioCard-arrow-purple {
  cursor: pointer;
  background-color: #7030A0;
  color: white;
}

.BioCard-orange:hover .BioCard-arrow-orange {
  cursor: pointer;
  background-color: #FFA629;
  color: white;
}

.BioCard-purple:hover svg {
  fill: white;
}

.BioCard-headshot {
  position: relative;
  z-index: 0;
  object-fit: cover;
  object-position: 100% 0;
  border-style: solid;
  border-width: 5px;
  border-radius: 55px;
}

.BioCard-drawing {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
  transition-duration: 300ms;
  transition-timing-function: ease-in;
  border-style: solid;
  border-width: 8px;
  border-radius: 55px;
}

.BioCard-blank-img {
  position: relative;
  z-index: 0;
  object-fit: cover;
  object-position: 100% 0;
  transition-duration: 300ms;
  transition-timing-function: ease-in;
}

.BioCard-blank-hover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
  transition-duration: 300ms;
  transition-timing-function: ease-in;
}

.BioCard-bingo {
  display: block;
}

.BioCard-surprise {
  display: block;
}

.BioCard-arrow-purple {
  position: absolute;
  z-index: 30;
  right: -1.25rem;
  bottom: -0.938rem;
  height: 2.75rem;
  width: 2.75rem;
  background-color: white;
  color: #7030A0;
  border: solid;
  border-width: 4px;
  border-radius: 50px;
  transition-duration: 300ms;
  transition-timing-function: ease-in;
}

.BioCard-arrow-orange {
  position: absolute;
  z-index: 30;
  right: -1.25rem;
  bottom: -0.938rem;
  height: 2.75rem;
  width: 2.75rem;
  background-color: white;
  color: #FFA629;
  border: solid;
  border-width: 4px;
  border-radius: 50px;
  transition-duration: 300ms;
  transition-timing-function: ease-in;
}

.BioCard-name {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-weight: 700;
  font-family: 'new-science-serif';
  font-size: 1.375rem;
}

.BioCard-pronouns, .BioCard-role {
  font-family: 'new-science-serif';
}

.BioCard-pronouns {
  font-size: 1.25rem;
  font-weight: 500;
}

.BioCard-role {
  font-size: 1.125rem;
  font-weight: 400;
}

@media screen and (min-width: 600px) {
  /* .BioCard-purple, .BioCard-orange, .BioCard-blank {
    width: 30%;
  } */

  .BioCard-headshot {
    border-width: 8px;
    border-radius: 55px;
  }

  .BioCard-arrow-purple {
    right: 0px;
    bottom: 0px;
  }

  .BioCard-arrow-orange {
    right: 0px;
    bottom: 0px;
  }

  .BioCard-pronouns, .BioCard-role {
    font-size: 1.5rem;
    font-weight: 400;
  }
}

@media screen and (min-width: 800px) {
  .BioCard-purple, .BioCard-orange, .BioCard-blank {
    width: 20%;
  }
}

@media screen and (min-width: 1000px) {
  .BioCard-bingo {
    display: none;
  }
}
