@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lalezar&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url("https://use.typekit.net/saa1sgu.css");
@import 'bootstrap/dist/css/bootstrap.min.css';

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 80%;
  }
}

h1,
h2,
h3 {
  font-family: 'new-science-serif', sans-serif;
}



h1 {
  font-size: 2.875rem;
  line-height: 6.856rem;
  font-weight: 700;
}

h2 {
  font-size: 1.875rem;
  line-height: 4.375rem;
  font-weight: 600;
}

.nav-link {
  font-family: 'Hiragano Kuku Gothic', sans-serif;
  src: url('../src/assets/Hiragino\ Kaku\ Gothic\ ProN.ttf') format('tff');
}

li {
  font-family: 'new-science-serif', sans-serif;
  src: url('../src/assets/Hiragino\ Kaku\ Gothic\ ProN.ttf') format('tff');
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 36px;
}

p {
  font-family: 'new-science-serif', sans-serif;
  font-size: 1.50rem;
  line-height: 1.5rem;
}

small {
  font-family: 'new-science-serif', sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

Button {
  font-family: 'Hiragaino Kaku Gothic ProN', sans-serif;
  src: url('../src/assets/Hiragino\ Kaku\ Gothic\ ProN.ttf') format('tff');
}

/* button styling */
/* primary button */
.btn-primary {
  background-color: #7030A0;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: 0;
  font-size: 1em;
  border-radius: 30px;
  font-size: 30px;
  font-family: 'Hiragaino Kaku Gothic ProN', sans-serif;
  src: url('../src/assets/Hiragino\ Kaku\ Gothic\ ProN.ttf') format('tff');
  height: 70px;
  transition-duration: 300ms;
  transition-timing-function: ease-in;
}

.btn-primary,
.btn-primary:focus-visible {
  background-color: #7030AD;
}

.btn-primary:hover {
  background-color: #710071;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background-color: rgb(100, 0, 100);
}

/* info button solid */

.btn-info,
.mobile-nav-play-btn {
  background-color: #E21674;
  border-color: #E21674;
  color: #ffff;
}

.btn-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0rem;
  padding: 2rem 3rem;
  outline: none;
  border: 0;
  max-width: min-content;
  white-space: nowrap;
  color: #fff;
  font-weight: 800;
  font-size: 1.5rem;
  letter-spacing: .4rem;
  text-transform: uppercase;
  font-family: 'Hiragaino Kaku Gothic ProN', sans-serif;
  src: url('../src/assets/Hiragino\ Kaku\ Gothic\ ProN.ttf') format('tff');
  border-radius: 30px;
  height: 70px;
  transition-duration: 300ms;
  transition-timing-function: ease-in;
}

.nav-play-btn {
  width: 264px !important;
  margin-right: 1rem;
}

.btn-info:active,
.btn-info:active .btn-info:hover,
.btn-info:focus-visible {
  background-color: #E21674;
  color: #ffff;
  border: 1px solid #E21674;
}

.btn-info-check:checked+.btn,
.btn-info.show,
.btn-info:first-child:active,
:not(.btn-info-check)+.btn-info:active {
  background-color: #ca1468;
  color: #fff;
  border: 1px solid #ca1468;
}

.btn-info:hover {
  background-color: #d10060;
  color: #fff;
}

/* .btn-info:active,
.btn-info:focus,
.btn-info:focus-visible {
  background-color: #fff !important;
  border: 4px solid #E21674 !important;
} */

/* Change the color of the text inside the button when active */
.btn-info:active a,
.btn-info:focus a,
.btn-info:focus-visible a {
  color: #E21674;
}

/* info button outline */

.btn-outline-info {
  border-color: #E21674;
  color: #E21674;
}

.btn-outline-info:active,
.btn-outline-info:hover,
.btn-outline-info:focus-visible {
  background-color: #E21674;
  color: #ffff;
  border: 1px solid #E21674;
}

.btn-outline-info-check:checked+.btn,
.btn-outline-info.active,
.btn-outline-info.show,
.btn-outline-info:first-child:active,
:not(.btn-outline-info-check)+.btn-outline-info:active {
  background-color: #ca1468;
  color: #fff;
  border: 1px solid #ca1468;
}