.AboutPage {
  margin-bottom: 1rem;
}

.AboutPage p {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.AboutPage-desktop {
  display: none;
}

.AboutPage-mobile {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.AboutPage-mobile h1 {
  text-align: center;
}

.AboutPage-mobile svg {
  position: absolute;
  right: 0rem;
  z-index: 1;
}

.AboutPage-mobile-juliane {
  position: relative;
  width: 14.125rem;
  height: 24rem;
  left: 20%;
  z-index: 10;
}

.AboutPage-mobile-jimena {
  display: block;
  position: relative;
  width: auto;
  height: 20.3rem;
  right: -3.125rem;
  z-index: 10;
}

.AboutPage-mobile-wei {
  position: relative;
  width: 14.8rem;
  height: 14.125rem;
  margin-top: 2rem;
  left: 2.5rem;
  z-index: 10;
}

.AboutPage-mobile-content {
  position: relative;
  margin: 2rem 3rem;
  z-index: 10;
}

.AboutPage-mobile-inco-logo {
  display: block;
  position: relative;
  margin: 2rem auto;
  width: 78%;
  height: auto;
  z-index: 10;
}

.AboutPage-readmore {
  font-size: 1.25rem;
  font-family: 'new-science-serif', 'sans-serif';
  font-weight: 700;
  text-transform: uppercase;
}

.AboutPage-readmore:hover {
  cursor: pointer;
}

.AboutPage-hide-readmore {
  background-color: #FFD30026;
  width: 2.938rem;
  height: 2.938rem;
  border-radius: 50px;
}

.AboutPage-caretdown {
  margin-top: -5px;
}

.AboutPage-caretup {
  margin-top: 2px;
  margin-left: 4px;
}

@media screen and (min-width: 600px) {
  .AboutPage-desktop {
    display: block;
  }

  .AboutPage-mobile {
    display: none;
  }

  .AboutPage-header img {
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: 0px;
    top: 3.188rem;
    width: 78%;
    height: auto;
    z-index: 10;
  }

  .AboutPage-our-story {
    position: relative;
    z-index: 20;
    margin-top: 0%;
    margin-bottom: 15%;
    margin-left: 80px;
    margin-right: 80px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 9.313rem;
  }

  .AboutPage-our-story img {
    max-width: 80%;
    height: auto;
    margin-top: 8%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 800px) {
  .AboutPage-our-story {
    flex-wrap: nowrap;
    margin-top: -10%;
    margin-bottom: 5%;
  }

  .AboutPage-our-story img {
    max-width: 30%;
    height: 30%;
  }
}

@media screen and (min-width: 1400px) {
  .AboutPage-our-story {
    margin-left: 160px;
    margin-right: 160px;
  }
  .AboutPage-our-story img {
    max-width: 20%;
    height: 20%;
    margin-top: 6%;
    margin-left: auto;
    margin-right: auto;
  }
}