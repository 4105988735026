.TermsOfServicePage {
  margin: 5rem 2rem 1rem 2rem;
}

.TermsOfServicePage p {
  line-height: 2rem;
}

.TermsOfServicePage h4, h5 {
  font-family: "new-science-serif", sans-serif;
}

.TermsOfServicePage h4 {
  font-size: 1.65rem;
}

.TermsOfServicePage h5 {
  font-size: 1.55rem;
}

@media screen and (min-width: 600px) {
  .TermsOfServicePage {
    margin: 5rem 5rem 1rem 5rem;
  }
}