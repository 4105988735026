.NotFoundPage {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 15.5rem;
  margin-left: 80px;
  margin-right: 80px;
}

.NotFoundPage-404 {
  width: 100%;
  font-family: "new-science-serif", sans-serif;
  font-size: 10rem;
  font-weight: 700;
  text-align: center;
}

.NotFoundPage-message {
  width: 100%;
  font-family: "new-science-serif", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.NotFoundPage-bar {
  margin-top: 0rem;
  margin-bottom: 3rem;
  height: 2px;
  width: 100%;
  border-radius: 3px;
  background: linear-gradient(90deg, red 0%, yellow 15%, lime 30%, cyan 50%, blue 65%, magenta 80%, red 100%);
  background-size: 200% 200%;
  animation: horizontalScroll 5s linear infinite;
  filter: blur(.7px);
}

@keyframes horizontalScroll {
  0% {
    background-position: 0% 0%
  }

  100% {
    background-position: -200% 0%;
  }
}

.NotFoundPage-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  width: 100%;
}

.NotFoundPage-button {
  width: 100%;
  text-transform: uppercase;
  font-family: 'Hiragino Kaku Gothic', sans-serif;
  font-weight: 800;
  font-size: 1.5rem;
  letter-spacing: .1em;
}

.NotFoundPage-button:hover {
  background-color: #710071;
}

@media screen and (min-width: 600px) {
  .NotFoundPage-buttons {
    flex-wrap: nowrap;
  }

  .NotFoundPage-button {
    width: 40%;
  }
}