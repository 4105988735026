/* Rest of code. */

.Footer {
    flex-direction: row;
    justify-content: center;
    background-color: transparent;
    overflow: hidden;
    background-image: url('../../assets/Footer\ curve.png');
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 600px;
    color: #ffff;
    z-index: 30;
    position: relative;
    padding: 8% 80px 2% 80px;
}

.Footer-mobile {
    flex-direction: row;
    justify-content: center;
    background-color: transparent;
    overflow: hidden;
    background-image: url('../../assets/mobile-footer-curve.png');
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 600px;
    color: #ffff;
    z-index: 30;
    position: relative;
    padding: 8% 20px 15% 20px;
}

.footer-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-frame {
    width: 70%;
    margin-top: 10rem;
    margin-bottom: 3rem;;
}

.footer-logo {
    object-fit: contain;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.footer-row h3 {
    font-size: 1.875rem;
    font-weight: 700;
}

.footer-row p {
    margin: 1.5em 0;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
}

.footer-title {
    text-decoration: underline;
}

.footer-items {
    margin: 0;
    padding: 0;
    text-align: center;
}

.footer-item {
    list-style: none;
    line-height: 3.25rem;
    display: block;
    white-space: nowrap;
}

.footer-item a {
    text-decoration: none;
    color: white;
    transition-duration: 100ms;
    transition-timing-function: ease-in;
}

.footer-item a:hover {
    color: #e21674;
}

.col-newsletter {
    text-align: center;
    justify-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.col-menu, .col-follow {
    align-self: flex-start;
    text-align: center;
}

.col-follow {
    margin-top: 3rem;
}

.share-logos {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 40px;
    margin-top: 2.5rem;
}

.share-logo {
    width: 60px;
    height: 60px;
    transition: 0.2s ease-in;
}

.share-logo:hover {
    transform: rotate(90deg);
    cursor: pointer;
}

.Footer-boring-stuff {
    margin-top: 2rem;
    text-align: center;
}

.Footer-boring-stuff a {
    color: white;
    transition-duration: 100ms;
    transition-timing-function: ease-in;
}

.Footer-boring-stuff a:hover {
    color: #e21674
}

.Rainbow-lines {
    display: flex;
    justify-content: center;
    margin-top: -1.5rem;
}

.Rainbow-lines-movement {
    height: 320px;
    width: 2px;
    border-radius: 3px;
    background: linear-gradient(0deg, red 0%, yellow 15%, lime 30%, cyan 50%, blue 65%, magenta 80%, red 100%);
    background-size: 200% 200%;
    animation: moveGradient 15s linear infinite;
    filter: blur(.7px);
}


@keyframes moveGradient {
    0% {
        background-position: 0% 200%
    }

    100% {
        background-position: 0% -200%;
    }
}


/* Media queries to handle which version loads on mobile/tablet/desktop */
.Footer {
    display: none;
}

.Footer-mobile {
    display: flex;
}

/* Show mobile version */
@media screen and (min-width: 600px) {
    .Footer-mobile {
        padding: 8% 50px 15% 50px;
    }

    .logo-frame {
        width: 40%;
    }

    .Rainbow-lines-left {
        display: none;
    }
}

/* Show desktop version */
@media screen and (min-width: 1000px) {
    .Footer {
        display: flex;
    }

    .Footer-mobile {
        display: none;
    }

    .logo-frame {
        width: 100%;
        margin-top: 1.5em;
        margin-bottom: 3rem;;
    }

    .footer-logo {
        object-fit: contain;
        height: auto;
        width: 10%;
    }

    .col-newsletter {
        text-align: left;
        width: 33%;
    }

    .col-menu {
        margin-top: 3rem;
    }

    .footer-items {
        text-align: left;
    }
}
