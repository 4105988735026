.BioModal-desktop {
  display: none;
}

.BioModal-mobile {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
}

.modal-dialog {
  max-width: 90%;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
}

.modal-body {
  padding: 0px;
}

.modal {
  --bs-modal-border-radius: 55px;
}

.modal-backdrop {
  background-color: #7030A0;
  --bs-backdrop-opacity: 0.65;
}

.BioModal-close {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  position: relative;
  top: -4.375rem;
  left: 85%;
  background-color: white;
  color: #6f30a05f;
  text-align: center;
  font-size: 1.875rem;
  transition: 0.15s ease-in;
}

.BioModal-mobile-graphics {
  position: relative;
  margin-top: -3.125rem;
  margin-bottom: 2.18rem;
  background-color: #7030A0;
  width: 100%;
  border-radius: 55px;
  padding: 1.34rem 3.56rem;
}

.BioModal-mobile-graphics img {
  border-radius: 49px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.BioModal-name {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-family: 'new-science-serif', 'sans-serif';
  font-weight: 700;
  color: white;
  padding: 0px;
}

.BioModal-pronouns {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-family: 'new-science-serif', 'sans-serif';
  font-weight: 400;
  color: white;
  padding-left: 0.938rem;
  vertical-align: middle;
}

.BioModal-role {
  text-align: center;
  font-size: 1.5rem;
  font-family: 'new-science-serif', 'sans-serif';
  font-weight: 400;
  color: white;
  width: 100%;
  margin: 0.25rem 0rem;
}

.BioModal-left {
  width: 36%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1.813rem;
}

.BioModal-left img {
  border-radius: 18px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.BioModal-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.81rem;
  margin-bottom: 3.25rem;
}

.BioModal-social-button {
  width: 80%;
  height: 4.375rem;
  margin: auto;
  background-color: #7030A0;
  border-radius: 30px;
}

.BioModal-social-button a {
  text-decoration: none;
  font-size: 1.875rem;
  font-weight: 800;
  color: white;
}

.BioModal-social-button:hover {
  background-color: #710071;
}

.BioModal-right {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
}

.BioModal-right-top {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.BioModal-info {
  width: 90%;
  font-size: 1.25rem;
  font-family: 'new-science-serif', 'sans-serif';
}

.BioModal-qanda {
  width: 90%;
  border: solid;
  border-color: #7030A0;
  border-width: 1px;
  border-radius: 30px;
  margin-top: 0.875rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;
  padding: 1.25px 1.5px;
  font-size: 1.125rem;
  font-family: 'new-science-serif', 'sans-serif';
  padding: 2%;
}

.BioModal-q {
  font-weight: 700;
}

.BioModal-a {
  color: #7030A0;
  font-weight: 700;
}

.BioModal-question {
  display: inline-block;
  margin-bottom: .75rem;
}

.BioModal-readmore {
  font-size: 1.25rem;
  font-family: 'new-science-serif', 'sans-serif';
  font-weight: 700;
  text-transform: uppercase;
}

.BioModal-readmore:hover {
  cursor: pointer;
}

.BioModal-reasonForJoining {
  margin-bottom: 1.875rem;
}

.BioModal-gainedFromInCO {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}

@media screen and (min-width: 600px) {
  .BioModal-desktop {
    display: flex;
    flex-wrap: nowrap;
    gap: 1.875rem;
  }

  .BioModal-mobile {
    display: none;
  }

  .modal {
    --bs-modal-border-radius: 1.875rem;
  }

  .modal-dialog {
    max-width: 80%;
    margin-top: 0px;
  }

  .modal-body {
    padding: 1.875rem 2.5rem;
  }

  .BioModal-social-button {
    width: 93%;
  }

  .BioModal-close {
    width: 3.313rem;
    height: 3.313rem;
    border-radius: 100px;
    position: block;
    top: 0px;
    left: 0px;
    background-color: #7030A0;
    color: white;
    text-align: center;
    font-size: 1.875rem;
    transition: 0.15s ease-in;
  }

  .BioModal-close:hover {
    background-color: #710071;
    transform: rotate(90deg);
    cursor: pointer;
  }

  .BioModal-name {
    text-align: left;
    font-size: 2.5rem;
    color: black;
    padding: 0px;
  }

  .BioModal-pronouns {
    display: block;
    font-size: 1rem;
    font-weight: 500;
    color: black;
    padding-left: 0rem;
    text-align: left;
  }

  .BioModal-role {
    text-align: left;
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
    width: 100%;
    margin: 0.25rem 0rem;
  }

  .BioModal-qanda {
    width: 100%;
    height: 18.75rem;
    padding: 1.25rem;
    margin-bottom: 0;
    border-radius: 8px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-color: #7030A0 #F2E8FD;
    scrollbar-width: thin;
  }
}

@media screen and (min-width: 1400px) {
  .BioModal-pronouns {
    display: inline;
    font-size: 1rem;
    font-weight: 500;
    color: black;
    padding-left: 0.938rem;
    vertical-align: middle;
  }
}

@media screen and (min-width: 1400px) {
  .modal-dialog {
    max-width: 60%;
  }

  .BioModal-info {
    width: 88%;
  }
}