#root>div>nav>div {
    padding: 0;
}

.nav-wrapper {
    width: 100%;
    background-color: rgb(255, 255, 255);
    position: sticky;
    top: 0;
    z-index: 30;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.nav-content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    gap: 2%;
    width: 100%;
}

.nav-content>ul {
    gap: 5%;
    width: 60%;
    justify-content: flex-end;
}

.logo-wrapper {
    width: max-content;
}

.logo-img {
    height: 5.5rem;
    width: auto;
    padding: 0;
    margin: 0;
}

.logo-img:hover {
    animation: logoShake 2s ease 0s 1 normal forwards;
    scale: 1.01;

}

.logo-img:active {
    scale: .98;
}

@keyframes logoShake {

    0%,
    100% {
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70% {
        transform: translateX(-1.3px);
    }

    20%,
    40%,
    60% {
        transform: translateX(1.3px);
    }

    80% {
        transform: translateX(0px);
    }

    90% {
        transform: translateX(0px);
    }
}

.nav-content ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu-item {
    font-weight: 300px;
}

li .nav-link {
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
    color: #000;
}

.nav-play-btn {
    border-radius: 30px;
    border: 0px solid transparent;
    width: 284px;
    height: 70px;
}

a>.nav-play-btn {
    font-weight: 800;
    font-size: 1.5em;
    line-height: 36px;
    letter-spacing: .15em;
    font-variant: normal;
    color: #fff;
    text-decoration: none;
}

a>.nav-play-btn:active:focus:target {
    color: #e11774;
}

.menu-item {
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    margin: 0 1rem;
    color: #8d8d8d;
    position: relative;
    cursor: pointer;
}

.menu-item::before {
    content: " ";
    width: 2rem;
    height: 0.3rem;
    background: #e11774;
    border-radius: 0.5rem;
    position: absolute;
    opacity: 0;
    bottom: -0.6rem;
    transform: translateX(-1rem);
    transition: all 0.3s ease;
}

.menu-item:hover::before {
    width: 100%;
    transform: translateX(0rem);
    opacity: 1;
}

.menu-btn {
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    min-height: auto;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    color: #fff;
    background: #e11774;
    line-height: 0;
    cursor: pointer;
    transition: all 0.04s ease;
    z-index: 9999;
    visibility: hidden;
}

.menu-btn-hidden {
    visibility: hidden;

}

.menu-btn:hover {
    color: #ffffff;
    background-color: #e11774;
}



@media (max-width: 1490px) {
    .nav-wrapper {
        max-width: 100% !important;
    }

    .nav-content {
        width: 83%;
    }

    #root>div>div.nav-wrapper>div>ul {
        gap: 8%
    }

}

@media (max-width: 1400px) {
    .nav-content {
        min-width: 86%;
    }
}

@media (max-width: 1360px) {
    .nav-content {
        width: 88%;
    }
}

@media (max-width: 1330px) {
    .nav-content {
        max-width: 90%;
        min-width: none;
    }
}

@media (max-width: 1280px) {

    .nav-content {
        max-width: 95%;
        min-width: none;
    }
}

@media (max-width: 1220px) {
    #root>div>div.nav-wrapper>div>ul {
        gap: 1.5%
    }
}

@media (max-width: 1200px) {
    .nav-content>ul {
        gap: 7%;
    }

    #root>div>div.nav-wrapper {
        min-width: 90% !important;
        padding: 0 !important;
    }
}

@media (max-width: 1038px) {

    #root>div>div.nav-wrapper {
        padding: 0 !important;
        gap: 3%;
    }

    .nav-content {
        width: 100%;
    }

    .nav-content ul li {
        gap: 1%;
    }
}

@media(max-width: 998px) and (min-width: 764px) {
    .nav-content {
        position: relative;
        width: 100%;
        max-width: 100%;
    }

    .nav-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1rem;
        height: 152px;
        width: 100%;
        position: relative;
    }

    .logo-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    #root>div>div.nav-wrapper>div>ul {
        display: none;
    }

    .menu-btn {
        position: absolute;
        left: 0;
        z-index: 9999 !important;
    }

    #root>div>nav>div>div.d-flex.align-items-center.justify-content-between.position-relative.flex-row-reverse>a,
    #root>div>nav>div>div.d-flex.align-items-center.justify-content-between.position-relative.flex-row>a {
        margin: .6em 0;
    }

    .logo-img {
        position: relative;
    }

    #root>div>nav>div>div.collapse.navbar-collapse.show {
        display: none;
    }

    #root>div>nav>div>div.d-flex.align-items-center.justify-content-between {
        flex-direction: row-reverse;
        width: 100%;
    }

    #root>div>nav>div>div.d-flex.align-items-center.justify-content-between.row {
        flex-direction: row;
        width: 100%;
    }

    #root>div>nav>div>div.d-flex.align-items-center.justify-content-between.row-reverse {
        flex-direction: row-reverse;
        width: 100%;
    }

    .menu-btn {
        border-radius: 50%;
        height: 2.6rem;
        width: 2.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        visibility: visible;
        transition: all 0.2s ease;
    }

    .menu-btn>span {
        padding: 0;
        margin: 0;

    }

    .material-symbols-outlined {
        font-size: 2.8rem;
    }

    .menu-btn:hover {
        border: none;
    }

    #root>div>div.nav-wrapper>div>ul>li {
        visibility: hidden;
    }

    #root>div>div.nav-wrapper>div>button.btn-info.nav-play-btn {
        display: none;
    }
}

@media (max-width: 764px) and (min-width: 320px) {
    .nav-content {
        position: relative;
        justify-content: center;
        align-items: center;
    }

    .menu-btn {
        position: absolute;
        left: 0;
    }

    .material-symbols-outlined {
        font-size: 2.8rem;
    }

    .nav-content ul,
    .nav-play-btn {
        display: none;
    }

    .logo-img {
        position: relative;
    }

    #root>div>nav>div>div.collapse.navbar-collapse.show {
        display: none;
    }
}